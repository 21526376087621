import {
  CHANGE_PREORDER_STATUS,
  CHAT_MESSAGE_COUNT,
  CLEAR_ONBOARDING,
  CURRENT_INDEX,
  INITIAL_LOGIN_INFO,
  IS_CHAT_OPEN,
  LAST_INDEX,
  MEMBERSHIP_DATA,
  UPDATE_EMAIL,
  UPDATE_INFO,
  UPDATE_PAYMENT,
  UPDATE_PHONE,
} from 'constants/actions';
import { generateUniqueRandomString, isValidMongoId, stickyBody } from 'helpers';
import { createContext, useContext } from 'react';
import { jwtDecode } from 'jwt-decode';
const OnboardingContext = createContext(null);

export const useMembershipData = () => {
  const { onboardingForms } = useContext(OnboardingContext);
  return onboardingForms.membershipData;
};
export const useGetUserId = () => {
  const { onboardingForms } = useContext(OnboardingContext);
  return onboardingForms.userId;
};

export const useGetUserType = () => {
  const { onboardingForms } = useContext(OnboardingContext);
  return onboardingForms.userType;
};

let sender = localStorage.getItem('unique_id');
if (!sender || !isValidMongoId(sender)) {
  let newId = generateUniqueRandomString(16);
  localStorage.setItem('unique_id', newId);
  sender = newId;
}
const decode = () => {
  try {
    return jwtDecode(localStorage.getItem('blackjet-website')) || null;
  } catch (error) {
    return null;
  }
};

const userId = decode()?._id || sender;
const userType = decode()?._id ? 'user' : 'guest';

// chat

export const initialOnboardingState = {
  email: '',
  phone: {},
  info: {},
  payment: {},
  isPreOrder: false,
  sessionToken: null,
  loginData: {},
  membershipData: null,
  currentIndex: 0,
  lastInd: [],

  // isResized no longer used
  isChatOpen: { open: false, isResize: false },
  userId: userId,
  userType: userType,
  messageCount: 0,
};

function generateRandomString(length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let randomString = '';
  const randomValues = new Uint32Array(length);
  window.crypto.getRandomValues(randomValues);
  for (let i = 0; i < length; i++) {
    const randomIndex = randomValues[i] % characters.length;
    randomString += characters.charAt(randomIndex);
  }
  return randomString;
}

export const OnboardingReducer = (state, action) => {
  switch (action.type) {
    case UPDATE_EMAIL:
      return { ...state, email: action.payload };
    case UPDATE_PHONE:
      return { ...state, phone: action.payload };
    case UPDATE_INFO:
      return { ...state, info: action.payload };
    case UPDATE_PAYMENT:
      return { ...state, payment: action.payload };
    case CLEAR_ONBOARDING:
      return { ...state, email: '', phone: {}, info: {}, payment: {} };
    case CHANGE_PREORDER_STATUS:
      return { ...state, isPreOrder: action.payload, sessionToken: generateRandomString(12) };
    case INITIAL_LOGIN_INFO:
      return { ...state, loginData: action.payload };
    case MEMBERSHIP_DATA:
      return { ...state, membershipData: action.payload };
    case CURRENT_INDEX: {
      return { ...state, currentIndex: action.payload };
    }
    case LAST_INDEX: {
      return { ...state, lastInd: action.payload };
    }

    case IS_CHAT_OPEN: {
      return { ...state, isChatOpen: chatFn(action) };
    }

    case CHAT_MESSAGE_COUNT: {
      return { ...state, messageCount: action.payload };
    }

    default:
      return state;
  }
};

const chatFn = (action) => {
  stickyBody(action.payload.open);
  return action.payload;
};

export default OnboardingContext;
