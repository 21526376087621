import React, { useEffect } from 'react';

const ScrollToTopOnMount = () => {
  useEffect(() => {
    // Scroll to the top of the page with smooth animation on component mount
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  }, []); // Empty dependency array ensures this effect runs only once after component mount

  return null; // Return null as this component doesn't render anything
};

export default ScrollToTopOnMount;