import React, { useState } from 'react';

import { Text } from 'components';
import HOMEDesktopTabletLandscapeHomecontent from 'components/Home';
import { Entermobilegetapp } from 'components/Popup';
import { Button } from '@mui/material';
import { showMessage } from 'utils/notify';
import CustomModal from 'components/modal/CustomModal';
import CommonButton from 'components/formcomponents/CommonButton';

const Home = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const handleOpenDialogClose = () => {
    setOpenDialog(false);
  };
  return (
    <>
      
      {/* <Button sx={{ mt: 50 }} onClick={() => setOpenDialog(true)}>
        Show alert
      </Button> */}
      <Entermobilegetapp />
      <div className='relative flex flex-col w-full mx-auto bg-gray-900 font-hauora sm:bg-inherit'>
        <HOMEDesktopTabletLandscapeHomecontent
          className='flex flex-col justify-start w-full m-auto bg-gray-900'
          only5membershipslefttext={
            <Text className='w-auto xs:text-[10px]  4k:!text-[24px] text-base text-white-A700 gap-1 sm:flex sm:justify-center'>
              <span className='font-normal text-left text-gray-100 font-hauora'>Only </span>
              <span className='font-bold text-left text-white-A700 font-hauora'>5</span>
              <span className='font-normal text-left text-gray-100 font-hauora'>
                {' '}
                memberships left at this price
              </span>
            </Text>
          }
          mocountertext={
            <Text className='font-semibold 4k:flex 4k:items-baseline md:text-2xl sm:text-[22px] text-[26px] text-center text-white-A700 w-auto'>
              <span className='font-medium 4k:text-[32px] text-white-A700 font-hauora'>2,499 </span>
              <span className='text-base font-light 4k:!text-[20px] text-white-A700 font-hauora'>
                /mo
              </span>
            </Text>
          }
          mocounterOne={
            <Text className='font-semibold md:text-2xl sm:text-[22px] text-[26px] text-center text-white-A700 w-auto'>
              <span className='font-medium text-white-A700 font-hauora'>2,499</span>
              <span className='text-white-A700 font-hauora'> </span>
              <span className='text-lg font-light text-white-A700 font-hauora'>/mo</span>
            </Text>
          }
          only5membershipthree={
            <Text className='w-auto text-base sm:text-[10px] font-medium text-gray-100'>
              <span className='font-normal text-left text-gray-100 font-hauora'>O</span>
              <span className='font-normal text-left text-gray-100 font-hauora'>nly </span>
              <span className='font-bold text-left text-white-A700 font-hauora'>5</span>
              <span className='font-normal text-left text-gray-100 font-hauora'>
                {' '}
                memberships left at this price
              </span>
            </Text>
          }
          descriptionFour={
            <Text className='font-light sm:text-[12px] activate-txt leading-[24.00px] text-base text-gray-400 w-full'>
              <span className='text-left activate-txt text-gray-400 font-hauora'>
                *Activate your membership at your leisure when you’re ready to start flying after
                we’ve launched. Memberships activated will auto-renew monthly. You may cancel your
                membership anytime.{' '}
              </span>
              <span className='activate-txt text-left text-gray-400 font-hauora'>
                Cancellations apply at the end of the monthly billing cycle.
              </span>
            </Text>
          }
        />
      </div>
    </>
  );
};

export default Home;
