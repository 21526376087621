import { useMediaQuery } from '@mui/material';
import { getMembershipDetails } from 'api/onboarding';
import { Button, Img, Line, Text } from 'components';
import { TextStrick } from 'components/Text';
import CommonButton from 'components/formcomponents/CommonButton';
import { CHANGE_PREORDER_STATUS } from 'constants/actions';
import OnboardingContext from 'context/OnboardingContext';
import { toggleDrawer } from 'helpers';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import { numberWithCommas } from 'utils';

const UnlimitedFlight = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width : 699px)');
  const { dispatchOnboardingForms } = useContext(OnboardingContext);
  const [details, setDetails] = useState(null);

  const handlePreOrder = () => {
    dispatchOnboardingForms({ type: CHANGE_PREORDER_STATUS, payload: true });
    if (isMobile) {
      // toggleDrawer()
      navigate('/phone-onboarding');
    } else {
      navigate('/smart-field?type=pre-order');
    }
  };

  const getDetails = async () => {
    let response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      setDetails(response?.data?.data || {});
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  if (!details || details.status === 'inactive') {
    return null;
  }

  return (
    <div className='unlimited-flights-wrap'>
      <div className='unlimited-flights-card'>
        <div className='unlimited-flights'>
          <h2 className='unlimited-heading'>
            Unlimited flights <span>for a</span> fixed monthly price
          </h2>
          <div className='online-price-wrap'>
            <div className='section-border' />
            <div className='online-price-text'>
              {details?.discountPrice && (
                <>
                  {/* Desktop & Tabs */}
                  {details?.usedSeats < 10 && (
                    <p className='only-members mobile-none'>
                      <span className='price-text'>Only</span>
                      <span className='price-text white-text'>{details?.usedSeats}</span>
                      <span className='price-text'>memberships left at this price</span>
                    </p>
                  )}
                  {details?.usedSeats >= 10 && (
                    <p className='only-members mobile-none'>
                      <span className='price-text'>Limited memberships left at this price</span>
                    </p>
                  )}
                </>
              )}
              <div className='discount-price-wrap'>
                <div className='cut-price'>
                  {details?.discountPrice && (
                    <p>
                      <TextStrick>{numberWithCommas(details?.latestPrice)}</TextStrick>
                      {/* <img
                        className='cut-price-img'
                        src='/images/img_vector20.svg'
                        alt='vectorTwenty'
                      /> */}
                    </p>
                  )}
                  {!details?.discountPrice && (
                    <p className='latest-price-without-discount'>
                      {numberWithCommas(details?.latestPrice)} <span className='month'>/mo</span>
                    </p>
                  )}
                </div>
                <div className='original-price'>
                  {details?.discountPrice && (
                    <p>
                      <span>{numberWithCommas(details?.discountPrice)}</span>
                      <span className='qnty-tag'>/mo</span>
                    </p>
                  )}
                </div>
              </div>

              {/* Discount seat */}

              {details?.discountPrice && (
                <>
                  {/* Mobile width 360px */}
                  {details?.usedSeats < 10 && (
                    <p className='only-members mobile-block'>
                      <span className='price-text'>Only</span>
                      <span className='price-text white-text'>{details?.usedSeats}</span>
                      <span className='price-text'>memberships left at this price</span>
                    </p>
                  )}
                  {details?.usedSeats >= 10 && (
                    <p className='only-members mobile-block'>
                      <span className='price-text'>Limited memberships left at this price</span>
                    </p>
                  )}
                </>
              )}
            </div>
          </div>
        </div>
        <CommonButton
          text={<div> {!details.preOrder ? 'Become a member' : 'Pre-order Now'} </div>}
          className='preorder-btn pre-order-simmer-effect'
          onClick={handlePreOrder}
        />
      </div>
    </div>
  );
};

export default UnlimitedFlight;
