import { formattedText } from 'components/Text';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { checkIcon, iconEdit, iconRestore } from './icon';
import { ListWithIcon, ListWithIcon2 } from 'components/feeback/Menu';
import { isTimeOutdated, truncateText } from 'helpers';
import { socket } from 'utils/socket';
import { useGetUserId, useGetUserType } from 'context/OnboardingContext';

const UserChat = ({
  chat,
  handleContextMenu,
  chatRef,
  editChat,
  selectedChat,
  setUnsendChat,
  handleUpdateMessage,
}) => {
  console.log(chat, 'user_chat');
  const [chatValue, setChatValue] = useState('');

  // const handleOpenOption = () => {
  //   setContextMenu({ ...contextMenu, visible: false });
  // };

  const isEditMode = editChat && selectedChat?._id === chat._id;
  console.log(editChat, selectedChat?._id, chat._id, 'isEditMode___');

  const isTimeOver = isTimeOutdated(chat?.createdAt, 5);
  console.log(isTimeOver, chat.message, 'isTimeOver___');

  useEffect(() => {
    if (isEditMode && chatRef?.current) {
      chatRef.current.focus();
      chatRef.current.style.height = 'auto'; // Reset height
      chatRef.current.style.height = `${chatRef.current.scrollHeight}px`; // Set height based on content
    }
  }, [chatRef, isEditMode]);

  const handleChange = (e) => {
    setChatValue(e.target.value);
  };

  return (
    <>
      <div className='black-jet-user' key={chat._id}>
        <div
          role='button'
          onClick={(event) => {
            !isEditMode && isTimeOver && handleContextMenu(event, chat);
          }}
          // ref={chatRef}
          className={`user-bot ${isEditMode && 'edit-user'} `}
        >
          {!isEditMode && (
            <>
              {chat?.media?.map((data, index) => {
                return <RenderImg data={data} key={index} />;
              })}

              <div className='user-text'>{formattedText(chat?.message)}</div>
            </>
          )}
          {isEditMode && (
            <div className='edit-user-container'>
              <div className='edit-user-msg'>
                <textarea
                  ref={chatRef}
                  className='edit-user-msg-input'
                  defaultValue={chat?.message}
                  rows={1}
                  onChange={handleChange}
                />
              </div>
            </div>
          )}
          {isEditMode && (
            <img
              src={checkIcon}
              alt='check'
              className='edit-user-icon'
              onClick={(e) => handleUpdateMessage(e, chatValue, chat)}
            />
          )}
        </div>
      </div>

      {/* <ContextMenu
        x={contextMenu.x}
        y={contextMenu.y}
        visible={contextMenu.visible}
        onClose={() => setContextMenu({ ...contextMenu, visible: false })}
      /> */}
    </>
  );
};

/**
 * ContextMenu component
 *
 * @prop {number} x X position of the ContextMenu
 * @prop {number} y Y position of the ContextMenu
 * @prop {boolean} visible Visibility of the ContextMenu
 * @prop {function} onClose Callback when the ContextMenu is closed
 */

export const ContextMenu = ({ visible, setEditChat, handleUnsendMessage, selectedChat }) => {
  const containerRef = useRef(null);
  const style = {
    // top: position.top,
    // left: position.left,
    // position: 'absolute',
  };
  const list = [
    {
      icon: iconEdit,
      title: 'Edit',
      onClick: () => setEditChat(true),
    },
    {
      icon: iconRestore,
      title: 'Unsend',
    },
  ];

  const editObj = {
    icon: iconEdit,
    title: 'Edit',
    onClick: () => setEditChat(true),
  };

  const unsendObj = {
    icon: iconRestore,
    title: 'Unsend',
    onClick: () => handleUnsendMessage(true),
  };

  return (
    <div style={style} className=''>
      <div
        className={`${
          visible ? 'attach-file-container-open' : 'attach-file-container-close'
        } attach-file-container`}
        ref={containerRef}
        style={{ maxHeight: visible ? containerRef?.current?.scrollHeight : 0 }}
      >
        <ul className='attach-file-list'>
          {selectedChat.message && (
            <ListWithIcon2 icon={editObj.icon} title={editObj.title} onClick={editObj.onClick} />
          )}
          <ListWithIcon2
            icon={unsendObj.icon}
            title={unsendObj.title}
            onClick={unsendObj.onClick}
          />
        </ul>
      </div>
    </div>
  );
};

export default UserChat;

export const AdminChat = ({ chat }) => {
  const [isEndChat, setIsEndChat] = useState(false);
  const sender_type = useGetUserType();
  const sender = useGetUserId();
  console.log(chat, 'chat___232');

  const handleDisconnect = () => {
    socket.emit('disconnected', { sender, sender_type, messageId: chat?._id });
  };

  const disconnectedInfo = (data) => {
    console.log(data, 'disconnectedInfo___');
    if (data.result) {
      setIsEndChat(true);
    }
  };

  useEffect(() => {
    //     disconnected
    // Request -- sender, sender_type
    if (socket) {
      socket.on('disconnected', disconnectedInfo);
    }
    return () => {
      socket.off('disconnected', disconnectedInfo);
    };
  }, []);

  console.log('admin_chat', chat);
  if (chat.message_type === 'disconnect') {
    return (
      <div className='black-jet-bot' key={chat._id}>
        <div className='chat-text-disconnect-container'>
          {!isEndChat && (
            <div className='chat-text-disconnect' role='button' onClick={handleDisconnect}>
              End chat session
            </div>
          )}
          {isEndChat && <div className='chat-ended'>You have ended the chat session</div>}
        </div>
      </div>
    );
  }
  return (
    <div className='black-jet-bot' key={chat._id}>
      <img
        alt='television'
        loading='lazy'
        src='/images/img_television.svg'
        className='my-auto aspect-[1.19] w-[19px]'
      />
      <div className='black-jet-bot-text'>
        {/* {chat.message_type === 'image' && <RenderImg img={chat?.image} />} */}
        {/* {chat.message_type === 'video' && <RenderVideo video_url={chat?.image} />} */}
        {chat?.media?.map((data, index) => {
          return <RenderImgAdmin data={data} />;
        })}
        <div className='chat-text'>{formattedText(chat?.message)}</div>
      </div>
    </div>
  );
};

const RenderImgAdmin = ({ data }) => {
  return (
    <div className='chat-img'>
      {data.type === 'image' && (
        <img src={data.mediaUrl} alt='television' className='rounded-sm h-auto' />
      )}

      {data.type === 'file' && <RenderPdf data={data} />}
      {data.type === 'video' && <RenderVideo data={data} />}
    </div>
  );
};
const RenderImg = ({ data }) => {
  return (
    <div className='chat-img'>
      {data.type === 'image' && (
        <img src={data.mediaUrl} alt='television' className='rounded-sm h-auto' />
      )}

      {data.type === 'file' && <RenderPdf data={data} />}
      {data.type === 'video' && <RenderVideo data={data} />}
    </div>
  );
};

const RenderPdf = ({ data }) => {
  console.log(data, '____pdf');
  const pdfName = data.mediaUrl.split('/').pop();
  const breakPdfName = pdfName.split('.');
  const fileName = breakPdfName.at(0);
  return (
    <div className='chat-pdf'>
      <a href={data.mediaUrl} target='_blank' rel='noopener noreferrer'>
        {truncateText(fileName, 20)}
        {breakPdfName.at(1)}
      </a>
    </div>
  );
};

const RenderVideo = ({ data }) => {
  console.log(data, '____video_url');
  return (
    <div>
      <video
        class='rounded-sm h-auto'
        controls
        // controlslist='nofullscreen'
        // playsinline=''
        poster={data.thumbnail}
        role='img'
        // data-inline-media-loop-to='true'
        // data-inline-media-basepath='/images/videoblackjet/Black_JET_LOW_BIT_RATE.mp4'
      >
        <source src={data.mediaUrl} type='video/mp4' class='custom-video' />
      </video>
    </div>
  );
};
