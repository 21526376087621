import React, { useContext, useState } from 'react';
import CommonLabel from 'components/formcomponents/CommonLabel';
import CommonInput from 'components/formcomponents/CommonInput';
import CommonButton from 'components/formcomponents/CommonButton';
import Errors from 'components/errors/Errors';
import { toggleDrawer } from 'helpers';
import OnboardingContext from 'context/OnboardingContext';
import { CHANGE_PREORDER_STATUS } from 'constants/actions';
import useOutsideClick from 'Hook/useOutsideClick';
import Share from 'components/share/Share';
import { useNavigate } from 'react-router-dom';
import { Entermobilegetapp } from 'components/Popup';
import { getMembershipDetails } from 'api/onboarding';
import { useMediaQuery } from '@mui/material';
import useIsMobile from 'Hook/useIsMobile';
import Landscape from 'components/phoneonboarding/Landscape';

const Profile = () => {
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);
  const [type, setType] = useState('reusable');
  const [details, setDetails] = React.useState(null);
  const navigate = useNavigate();
  const orientation = useMediaQuery('(orientation: portrait)');
  const isMobile = useIsMobile();

  useOutsideClick('share-btn', () => {
    const element = document.getElementById('share-btn');
    if (element) {
      if (element.classList.contains('open')) {
        element.classList.toggle('open');
      }
    }
  });

  const handleFreePreview = () => {
    // toggleDrawer()
    navigate('/phone-onboarding');
    dispatchOnboardingForms({ type: CHANGE_PREORDER_STATUS, payload: false });
  };
  const handleBecomeMember = () => {
    // toggleDrawer()
    navigate('/phone-onboarding');
    dispatchOnboardingForms({ type: CHANGE_PREORDER_STATUS, payload: true });
  };

  const handleTooltipOpen = () => {
    const element = document.getElementById('tooltip-item');
    if (element) {
      element.classList.toggle('open');
    }
  };

  const handleShare = async () => {
    // const element = document.getElementById("share-btn")
    // if (element) {
    //   element.classList.toggle("open");
    // }
    // async () => {
    try {
      if (navigator.share && typeof navigator.share === 'function') {
        await navigator.share({
          title: 'Black Jet Australia ',
          text: 'Black Jet Australia!',
          url: 'https://blackjet.au',
        });
      }
    } catch (error) {
      console.error('Unsupported', error);
    }
    // }
  };

  const handleReusableClick = () => {
    setType('reusable');
    handleTooltipOpen();
  };

  const handleGuestClick = () => {
    setType('guest');
    handleTooltipOpen();
  };

  const getDetails = async () => {
    let response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      setDetails(response?.data?.data || {});
    }
  };

  React.useEffect(() => {
    getDetails();
  }, []);

  if (!orientation && isMobile) {
    return <Landscape />;
  }

  return (
    <>
      <Entermobilegetapp />
      <div className='mob-profile-wrap'>
        <div className='profile-image'>
          <img src='/images/generic-user.svg' alt='' />
        </div>
        <div className='create-account-chip'>
          <span onClick={handleFreePreview}>Create a free account</span>
        </div>
        <div className='booking-passes-wrap'>
          <div className='booking-pass-card'>
            <p> Reusable Bookings</p>
            <div className='available-flights'>
              <p>
                <img className='flight-search-icon' src='/images/flight-icon.svg' alt='' /> 0
              </p>{' '}
              <span> of 0 available</span>
            </div>
            <div className='tooltip-text'>
              <img src='/images/tooltip-icon.svg' onClick={handleReusableClick} alt='' />
            </div>
          </div>
          <div className='booking-pass-card guest-card'>
            <p>Guest Passes</p>
            <div className='available-flights'>
              <p>
                <img className='flight-search-icon' src='/images/guest-users.svg' alt='' /> 0
              </p>{' '}
              <span>
                AWARDED EVERY <br /> 3 MONTHS
              </span>
            </div>
            <div className='tooltip-text'>
              <img src='/images/tooltip-icon.svg' onClick={handleGuestClick} alt='' />
            </div>
          </div>
        </div>
        <div id={'tooltip-item'} className='open-tooltip'>
          <div className='close-tool-tip'>
            {' '}
            <img src='/images/close-icon.svg' onClick={handleTooltipOpen} alt='' />{' '}
          </div>
          <p className='tooltip-heading'>
            <img src='/images/tooltip-icon.svg' alt='' />{' '}
            {type === 'reusable' ? 'Reusable Booking' : 'Guest Passes'}
          </p>
          {type === 'reusable' ? (
            <>
              <span>Each</span> Reusable Booking <span> allows you to</span> reserve <span>a</span>{' '}
              flight <span>and can be</span> reused unlimited number <span>of times</span>. After{' '}
              <span>each</span> flight, <span>the Reusable Booking immediately becomes</span>{' '}
              available <span>again to</span> book another flight.
            </>
          ) : (
            <span>
              Guest Passes are single-use tickets enabling you to bring a guest on your flight.
              However, the Guest Pass is more than a ticket—it's an emblem of the Black Jet prestige
              you can share. <br /> <br />
              Every three months a complementary Guest Pass is reserved for you, letting you
              introduce a chosen one to the echelons of our community. With an active Black Jet
              membership, your yet-to-be-used Guest Passes never expire.
            </span>
          )}
        </div>
        <div className='membner-wrap-btn'>
          <div role='button' className='become-btn-wrap' onClick={handleBecomeMember}>
            <div className='member-btn-group '>
              <span> What is your time and ease worth?</span>
              <CommonButton
                text={
                  <div className='flex items-center justify-evenly w-full'>
                    <img src='/images/Black Jet Logo.svg' alt='blackjet logo' />
                    {!details?.preOrder ? 'Become a member' : 'Pre-order now'}
                    {/* Become a member */}
                  </div>
                }
                type='submit'
              />
            </div>
            <div className='right-arrow'>
              <img src='/images/Chevron.svg' alt='' />
            </div>
          </div>
          <div className='become-btn-wrap' role='button' onClick={handleFreePreview}>
            <div className='member-btn-group'>
              <span>
                <img alt='Login' src='/images/login_icon.svg' /> Log in
              </span>
            </div>
            <div className='right-arrow'>
              <img src='/images/Chevron.svg' alt='' />
            </div>
          </div>
        </div>
        <div className='membner-wrap-btn'>
          <div className='become-btn-wrap share-btn'>
            <div className='member-btn-group'>
              <span>Share with a friend!</span>
              <CommonButton
                onClick={handleShare}
                text={
                  <>
                    <img src='/images/Share.svg' alt='share icon' /> Share
                  </>
                }
                type='submit'
              />
            </div>
          </div>
        </div>
        <Share />
      </div>
    </>
  );
};

export default Profile;
