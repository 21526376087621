import { useState, useEffect, useRef } from 'react';

export function useDebounce(value, delay) {
  const [debouncedValue, setDebouncedValue] = useState(value);

  useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay);

    // Cleanup function to clear timer on unmount or dependency change
    return () => clearTimeout(timer);
  }, [value, delay]);

  return debouncedValue;
}
