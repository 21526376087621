import { useMediaQuery } from '@mui/material';
import Legal from 'components/legal/Legal';
import useQueryParams from 'Hook/useQueryParams';
import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';

const PhoneOnboardingLayout = () => {
  const isMobile = useMediaQuery('(max-width:699px)');
  const location = useLocation();
  return (
    <>
      <Outlet />
      {isMobile && location.pathname === '/phone-onboarding' && (
        <>
          {/* <Legal /> */}
        </>
      )}
    </>
  );
};

export default PhoneOnboardingLayout;
