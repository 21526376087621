import React, { useEffect, useState } from 'react';
import { getviewLegal } from 'services/api';

const Membership = (id) => {
  const [selectedLegal, setSelectedLegal] = useState(null);

  const viewLegalById = async () => {
    setSelectedLegal(null);
    try {
      let payload = id;
      const res = await getviewLegal(payload);
      console.log(res, 'this_is_response');
      setSelectedLegal(res?.data?.data);

      // setSelectedId(res?.data?.data[0]._id || {});
    } catch (error) {
      console.log(error);
      setSelectedLegal(null);
    }
  };

  // console.log(id)

  useEffect(() => {
    if (id?.id !== '' && id?.id !== undefined) {
      viewLegalById();
    }
  }, [id]);

  if (!selectedLegal) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div className="flex flex-col w-full font-['Hauora'] items-start">
        <div className='legal-title'>{selectedLegal?.legal_title}</div>

        <div
          className='static-pages-content'
          id='render-text'
          dangerouslySetInnerHTML={{ __html: selectedLegal.legalContent }}
        ></div>
      </div>
    </>
  );
};

export default Membership;
