import React, { lazy, useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, useLocation } from 'react-router-dom';
import Loadable from 'components/Loadable';

import NotFound from 'pages/NotFound';
import Faq from 'components/faq/Faq';
import Legal from 'components/legal/Legal';
import GetContact from 'components/getcontact/GetContact';
import Careers from 'components/careers/Careers';
import JobPage from 'components/jobpage/JobPage';
import Membership from 'components/legal/Membership';
import OnboardingEmail from 'components/onboarding/OnboardingEmail';
import Header from 'components/Header';
import Footer from 'components/Footer';
import BasicInfo from 'components/onboarding/BasicInfo';
import PreorderPeriod from 'components/onboarding/PreorderPeriod';
import PhoneRecognized from 'components/onboarding/PhoneRecognized';
import PaymentMethod from 'components/onboarding/PaymentMethod';
import AboutUs from 'components/aboutus/AboutUs';
import NewsArticle from 'components/newsarticle/NewsArticle';
import Media from 'components/media/Media';
import Invester from 'components/invester/Invester';
import EmailToPhone from 'components/onboarding/EmailToPhone';
import Home from 'pages/Home';
import MembershipSelection from 'components/onboarding/MembershipSelection';
import CommonLayout from 'layouts/CommonLayout';
import OnboardingLayout from 'layouts/OnboardingLayout';
import FullScreen from 'components/Adaywithblack/FullScreen';
// import Virtualview from 'components/Virtualview';
import Profile from 'components/ProfilePage/Profile';
// import Booking from 'components/Booking/Booking';
import PhoneOnboarding from 'components/phoneonboarding/PhoneOnboarding';
import PhoneOnboardingLayout from 'layouts/PhoneOnboardingLayout';
import { AnimatePresence } from 'framer-motion';
import ChatBox from 'components/Footer/ChatBox';
// import MapAnimation from 'components/Svgs';

// const Faq = Loadable(lazy(() => import('components/faq/Faq')));
// const Legal = Loadable(lazy(() => import('components/legal/Legal')));
// const GetContact = Loadable(lazy(() => import('components/getcontact/GetContact')));
// const Careers = Loadable(lazy(() => import('components/careers/Careers')));
// const JobPage = Loadable(lazy(() => import('components/jobpage/JobPage')));
// const Membership = Loadable(lazy(() => import('components/legal/Membership')));
// const OnboardingEmail = Loadable(lazy(() => import('components/onboarding/OnboardingEmail')));
// const BasicInfo = Loadable(lazy(() => import('components/onboarding/BasicInfo')));
// const PreorderPeriod = Loadable(lazy(() => import('components/onboarding/PreorderPeriod')));
// const PhoneRecognized = Loadable(lazy(() => import('components/onboarding/PhoneRecognized')));
// const PaymentMethod = Loadable(lazy(() => import('components/onboarding/PaymentMethod')));
// const AboutUs = Loadable(lazy(() => import('components/aboutus/AboutUs')));
// const NewsArticle = Loadable(lazy(() => import('components/newsarticle/NewsArticle')));
// const Media = Loadable(lazy(() => import('components/media/Media')));
// const Invester = Loadable(lazy(() => import('components/invester/Invester')));
// const EmailToPhone = Loadable(lazy(() => import('components/onboarding/EmailToPhone')));
// const Home = Loadable(lazy(() => import('pages/Home')));
// const MembershipSelection = Loadable(lazy(() => import('components/onboarding/MembershipSelection')));
// const CommonLayout = Loadable(lazy(() => import('layouts/CommonLayout')));
// const OnboardingLayout = Loadable(lazy(() => import('layouts/OnboardingLayout')));
// const FullScreen = Loadable(lazy(() => import('components/Adaywithblack/FullScreen')));
const Virtualview = Loadable(lazy(() => import('components/Virtualview')));
// const Profile = Loadable(lazy(() => import('components/ProfilePage/Profile')));
const Booking = Loadable(lazy(() => import('components/Booking/Booking')));
// const PhoneOnboarding = Loadable(lazy(() => import('components/phoneonboarding/PhoneOnboarding')));
// const PhoneOnboardingLayout = Loadable(lazy(() => import('layouts/PhoneOnboardingLayout')));
const MapAnimation = Loadable(lazy(() => import('components/Svgs')));
// const Home = React.lazy(
//   () => import("pages/Home"),
// );

export const ROUTE_LIST = {
  HOME: '/',
  FAQ: '/faqs',
  LEGAL: '/legal',
  CONTACT_US: '/contactus',
  CAREERS: '/careers',
  JOB_PAGE: '/job-page',
  ABOUT_US: '/aboutus',
  NEWS: '/news',
  MEDIA: '/media',
  INVESTORS: '/investors',
  PROFILE: '/profile',
  BOOKING: '/booking',
  NOT_FOUND: '*',
};

const ProjectRoutes = () => {
  const [open, setOpen] = useState(false);
  const location = useLocation();
  return (
    <AnimatePresence mode='wait' initial={false}>
      <ChatBox />

      <Routes location={location} key={location.pathname}>
        <Route element={<CommonLayout open={open} setOpen={setOpen} />}>
          <Route path='/' element={<Home />} />
          <Route path={ROUTE_LIST.FAQ} element={<Faq />} />
          <Route path={ROUTE_LIST.LEGAL} element={<Legal />} />
          <Route path={ROUTE_LIST.CONTACT_US} element={<GetContact />} />
          <Route path='/careers' element={<Careers />} />
          <Route path='/job-page/:id' element={<JobPage />} />
          <Route path={ROUTE_LIST.ABOUT_US} element={<AboutUs />} />
          <Route path='/news' element={<NewsArticle />} />
          <Route path='/media' element={<Media />} />
          <Route path='/investors' element={<Invester />} />
          <Route path='/profile' element={<Profile />} />
          <Route path='/booking' element={<Booking open={open} setOpen={setOpen} />} />
          <Route path='*' element={<NotFound />} />
        </Route>
        <Route element={<OnboardingLayout />}>
          <Route path='/membership' element={<Membership />} />
          <Route path='/email-address' element={<OnboardingEmail />} />
          <Route path='/compendium' element={<BasicInfo />} />
          <Route path='/gratias-tibi-ago' element={<PreorderPeriod />} />
          <Route path='/smart-field' element={<PhoneRecognized />} />
          <Route path='/refer/:id' element={<PhoneRecognized />} />
          <Route path='/refined-selection' element={<MembershipSelection />} />
          <Route path='/phone-number' element={<EmailToPhone />} />
          <Route path='/at-your-convenience' element={<PaymentMethod />} />
          <Route path='/a-day-with-blackjet' element={<FullScreen />} />
        </Route>
        <Route element={<PhoneOnboardingLayout />}>
          <Route path='/refer/:id' element={<PhoneOnboarding />} />
          <Route path='/phone-onboarding' element={<PhoneOnboarding />} />
        </Route>
        <Route path='/virtual-view' element={<Virtualview />} />
        <Route path='/mobile-animation' element={<MapAnimation isRoute={true} />} />
      </Routes>
    </AnimatePresence>
  );
};
export default ProjectRoutes;
