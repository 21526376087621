import { useMediaQuery } from '@mui/material';
import { Button, Text } from 'components'
import CommonButton from 'components/formcomponents/CommonButton';
import React from 'react'
import { Link } from "react-router-dom";

const StillUnsure = () => {
  const isMobile = useMediaQuery("(max-width:699px)")
  return (
    <div>
     <div className="still-unsure-wrap">
          <h2>
         <span> Still unsure?  </span> Register for free <p>and explore the app</p>
          </h2>
          <Link to={isMobile ? "/phone-onboarding" :"/smart-field"}>
            <CommonButton pressedClass='pressed-arrow' text={"Create a free account"} className="dark-btn" />
          </Link>
        </div> 
    </div>
  )
}

export default StillUnsure
