import { useMediaQuery } from '@mui/material';
import { getMembershipDetails } from 'api/onboarding';
import { Button, Img, Text } from 'components';
import DownArrow from 'components/Home/DownArrow';
import CommonButton from 'components/formcomponents/CommonButton';
import { CHANGE_PREORDER_STATUS } from 'constants/actions';
import OnboardingContext from 'context/OnboardingContext';
import { toggleDrawer } from 'helpers';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';

import screenShotJet from './screenShotJet.png';
import useLowPowerModeEnabled from 'Hook/useLowPowerModeEnabled';

const FlyFreely = () => {
  const isMobile = useMediaQuery('(max-width : 699px)');
  const navigate = useNavigate();
  const videoCurrent = useRef(null);
  const [details, setDetails] = React.useState(null);
  const { dispatchOnboardingForms } = useContext(OnboardingContext);
  const isLowPower = useLowPowerModeEnabled();

  const [showVideo, setShowVideo] = useState(false);

  const handlePreOrder = () => {
    dispatchOnboardingForms({ type: CHANGE_PREORDER_STATUS, payload: true });
    if (isMobile) {
      // toggleDrawer()
      navigate('/phone-onboarding');
    } else {
      navigate('/smart-field?type=pre-order');
    }
  };

  const getDetails = async () => {
    let response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      setDetails(response?.data?.data || {});
    }
  };

  useEffect(() => {
    getDetails();
  }, []);

  const handleCanPlay = async () => {
    try {
      if (videoCurrent.current) {
        await videoCurrent.current?.play();
        setShowVideo(true);
      }
    } catch (error) {
      console.log(error);
      setShowVideo(false);
    }
  };

  const handlePlay = () => {
    setShowVideo(true);
    videoCurrent.current.style.opacity = 0.4; // Show the video on successful playback
  };

  const handleError = () => {
    videoCurrent.current.style.display = 'none'; // Hide the video on error
    setShowVideo(true);
  };

  const handleSuspend = () => {
    if (videoCurrent.current.paused) {
      // Low power mode might have paused the video
      console.warn('Video playback suspended in low power mode');
      videoCurrent.current.style.opacity = 0.4; // Dim the video instead of hiding
      try {
        videoCurrent.current.play();
      } catch (error) {
        console.log('Low_power_mode', error);
        setShowVideo(false);
      }
    }
  };

  console.log(showVideo, 'show_video');

  useEffect(() => {
    if (videoCurrent && videoCurrent.current) {
      videoCurrent.current.addEventListener('canplay', handleCanPlay);
      videoCurrent.current.addEventListener('play', handlePlay);
      videoCurrent.current.addEventListener('error', handleError);
      videoCurrent.current.addEventListener('suspend', handleSuspend);
    }

    return () => {
      // Cleanup function to remove event listeners
      if (videoCurrent && videoCurrent.current) {
        videoCurrent.current.removeEventListener('canplay', handleCanPlay);
        videoCurrent.current.removeEventListener('play', handlePlay);
        videoCurrent.current.removeEventListener('error', handleError);
        videoCurrent.current.removeEventListener('suspend', handleSuspend);
      }
    };
  }, []);

  return (
    <div>
      <div className='fly-freely relative'>
        {/* <div className="absolute w-full h-full heroOverlay"></div> */}

        <>
          {/* <ReactPlayer
            className='custom-video object-cover w-full h-full opacity-40'
            style={{
              display: showVideo ? 'block' : 'none',
            }}
            url={[
              {
                src: 'https://blackjetstoragebuck.s3.ap-southeast-2.amazonaws.com/1713160165357Black+Jet+Cut+720P+Low+Bitrate+2024.3.mp4',
                type: 'video/mp4',
              },
              { src: '/images/videoblackjet/Black_JET_LOW_BIT_RATE.mp4', type: 'video/mp4' },
            ]}
            playing
            loop
            muted
            playIcon={<></>}
            fallback={
              <>
                <img src={screenShotJet} className='w-full h-full object-cover' alt='' />
              </>
            }
            // controls
            width='100%'
            height='100%'
            onError={() => setShowVideo(false)}
            onReady={() => setShowVideo(true)}
          /> */}
          <video
            className='custom-video object-cover w-full h-full opacity-40'
            autoPlay
            loop
            muted
            controlsList='nofullscreen'
            webkit-playsinline
            playsInline
            ref={videoCurrent}
            poster={screenShotJet}
            role='img'
            data-inline-media-loop-to='true'
            data-inline-media-basepath={'/images/videoblackjet/Black_JET_LOW_BIT_RATE.mp4'}
            style={{
              display: showVideo ? 'block' : 'none',
            }}
          >
            <source
              // src={
              //   'https://blackjetstoragebuck.s3.ap-southeast-2.amazonaws.com/1713160165357Black+Jet+Cut+720P+Low+Bitrate+2024.3.mp4'
              // }
              src={'/images/videoblackjet/Black_JET_LOW_BIT_RATE.mp4'}
              type='video/mp4'
              className='custom-video'
            />
          </video>
        </>

        <img
          style={{ display: showVideo ? 'none' : 'block' }}
          className='object-cover w-full h-full opacity-40'
          src={screenShotJet}
          alt='thumb'
        />

        <div className='fly-txt'>
          <p className='main-heading'>
            Fly freely
            <img
              className='object-cover  vector-des w-full h-6'
              src='/images/img_stroke.svg'
              alt='stroke'
            />
            <img
              className='vector-mobile w-full h-6'
              src='/images/vector-mobile.svg'
              alt='stroke'
            />
          </p>
          <p className='second-p '>Unlimited Sydney-Melbourne flights for a flat monthly price</p>
          <div className='top-up-btn'>
            <p className='personal-p' size='txtHauoraBold32'>
              Your Personal Aviation
            </p>
            {/* <Button
              className="preorder-button"
              shape="round"
              color="white_A700"
              size="md"
              variant="fill"
              onClick={handlePreOrder}
            >
              Pre-order now
            </Button> */}
            <CommonButton
              className={'preorder-button mx-auto'}
              text={!details?.preOrder ? 'Become a member' : 'Pre-order now'}
              onClick={handlePreOrder}
            />
          </div>
          <DownArrow />
        </div>
      </div>
    </div>
  );
};

export default FlyFreely;
