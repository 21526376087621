import React, { useEffect, useState } from 'react';
export const INITIAL_TIMING = 10;
const ResendButton = ({ resendOtp }) => {
  const [countdown, setCountdown] = useState(INITIAL_TIMING);

  useEffect(() => {
    if (countdown > 0) {
      setTimeout(() => setCountdown(countdown - 1), 1000);
    }
    return () => {
      clearTimeout();
    };
  }, [countdown]);

  return (
    <div className='resend-btn'>
      <button
        onClick={() => resendOtp(setCountdown)}
        id='ResendCode'
        disabled={countdown > 0}
        className="text-center !bg-[#333333] disabled:cursor-not-allowed disabled:text-gray-600 text-sm font-['Hauora'] font-bold p-2 rounded-[39px] text-[#f2f2f2]"
        style={{
            color: countdown === 0 ? '#F2F2F2' : '#7A7A7A',
        }}
      >
        Resend code {countdown > 0 ? `(${countdown})` : ''}
      </button>
    </div>
  );
};

export default ResendButton;
