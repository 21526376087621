import React from 'react';
import ReactDOM from 'react-dom';
import App from './App';

import { ThemeProvider } from '@mui/material/styles';
import { CssBaseline } from '@mui/material';
import './styles/index.css';
// import "./styles/tailwind.css";
import './styles/style.scss';
import theme from 'styles/theme/theme';
// index.js
ReactDOM.render(
  <React.StrictMode>
    {/* <ThemeProvider theme={theme}> */}
      {/* <CssBaseline /> */}
      <App />
    {/* </ThemeProvider> */}
  </React.StrictMode>,
  document.getElementById('root'),
);
