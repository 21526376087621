import { useMediaQuery } from '@mui/material';
import { getMembershipDetails } from 'api/onboarding';
import { Text } from 'components';
import CardSection from 'components/CardSection';
import { TextAccent, TextSecondary } from 'components/Text';
import React, { useEffect, useState } from 'react';

const LaunchingFall = () => {
  const [details, setDetails] = useState(null);
  const isMobile = useMediaQuery('(max-width : 699px)');
  const getDetails = async () => {
    let response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      setDetails(response?.data?.data || {});
    }
  };
  useEffect(() => {
    getDetails();
  }, []);
  return (
    <div>
      <div className='launching-div'>
        <div className=' launching-inner'>
          <div className='launching-txt'>
            {details?.preOrder && (
              <>
                {isMobile ? (
                  <h1>Launching Spring 2025</h1>
                ) : (
                  <h1>
                    Launching <br />
                    Spring 2025
                  </h1>
                )}
                <h2 className={`${isMobile ? '' : 'whitespace-nowrap'}`}>
                  Flying private
                  <span> is now a </span>smart financial<br></br> decision
                </h2>
              </>
            )}
            {!details?.preOrder && (
              <h2 className={`${isMobile ? '' : 'whitespace-nowrap'}`}>
                Flying private <TextSecondary> is now a </TextSecondary>
                smart <br /> financial decision
              </h2>
            )}
          </div>
          <div className='col-md-6 card-section-wrapper'>
            <CardSection button={true} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default LaunchingFall;
