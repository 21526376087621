import { TextStrick } from 'components/Text';
import React from 'react';
import { numberWithCommas } from 'utils';

const PaymentText = ({ details }) => {
  return (
    <div className='payment-method-text'>
      {details?.discountPrice && (
        <div>
          <span className='latest-price-with-discount'>
            <TextStrick>{numberWithCommas(details?.latestPrice)}</TextStrick>
          </span>
          <span className='discount-price-wrapper'>
            <span className='discount-price'>{numberWithCommas(details?.discountPrice)}</span>
            <span className='month-discount'> /mo</span>
          </span>
        </div>
      )}
      {!details?.discountPrice && (
        <>
          <h3 className='latest-price-without-discount'>
            {numberWithCommas(details?.latestPrice)}
            <span className='month'> /mo</span>
          </h3>
        </>
      )}
    </div>
  );
};

export default PaymentText;

export const InitiationFee = ({ details }) => {
  const initiationFees = () => {
    return (
      numberWithCommas(
        details?.discountInitiationFees
          ? Number(details?.discountInitiationFees) > 0
            ? Number(details?.discountInitiationFees)
            : ''
          : Number(details?.initiationFees),
      ) + ''
    );
  };
  return (
    <div className='payment-initiation-fee'>
      <span className='discount-initiation-fee-strike'>
        {details?.discountInitiationFees && (
          <TextStrick>{numberWithCommas(details?.initiationFees)}</TextStrick>
        )}{' '}
      </span>
      <span
        className={
          details?.discountInitiationFees
            ? 'discount-initiation-fee-text'
            : 'discount-initiation-fee-strike'
        }
      >
        {initiationFees()}
      </span>
    </div>
  );
};
