import { useMediaQuery } from '@mui/material';
import { getMembershipDetails } from 'api/onboarding';
import { Text } from 'components';
import CommonButton from 'components/formcomponents/CommonButton';
import { TextStrick } from 'components/Text';
import { CHANGE_PREORDER_STATUS } from 'constants/actions';
import OnboardingContext from 'context/OnboardingContext';
import { toggleDrawer } from 'helpers';
import useQueryParams from 'Hook/useQueryParams';
import { useContext, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router';
import { numberWithCommas } from 'utils';

let lastKnownPosition = 0;
let ticking = false;
let animationFrameId = null;

export const getTotalPrice = (details) => {
  // if discount on latestPrice
  if (details?.discountPrice && details?.discountInitiationFees) {
    return numberWithCommas(Number(details?.discountPrice) + Number(details?.discountInitiationFees));
  }
  if (details?.discountPrice && !details?.discountInitiationFees) {
    return numberWithCommas(Number(details?.discountPrice) + Number(details?.initiationFees));
  }

  if (!details?.discountPrice && details?.discountInitiationFees) {
    return numberWithCommas(Number(details?.latestPrice) + Number(details?.discountInitiationFees));
  }

  if (!details?.discountPrice && !details?.discountInitiationFees) {
    return numberWithCommas(Number(details?.latestPrice) + Number(details?.initiationFees));
  }


  // // mean demo mode
  // if (details?.discountInitiationFees && details?.discountPrice) {
  //   return numberWithCommas(
  //     Number(details?.discountInitiationFees) + Number(details?.discountPrice),
  //   );
  // }

  // if (details?.initiationFees && details?.latestPrice) {
  //   return numberWithCommas(Number(details?.initiationFees) + Number(details?.latestPrice));
  // }

  // if (details?.discountInitiationFees && details?.latestPrice) {
  //   return numberWithCommas(Number(details?.discountInitiationFees) + Number(details?.latestPrice));
  // }

  // if (details?.discountInitiationFees && !details?.discountPrice) {
  //   return numberWithCommas(
  //     Number(details?.discountInitiationFees) + Number(details?.initiationFees),
  //   );
  // }
};

const CardSection = (props) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const type = queryParams.type || '';
  const isMobile = useMediaQuery('(max-width : 699px)');
  const { dispatchOnboardingForms } = useContext(OnboardingContext);
  const [details, setDetails] = useState({});

  useEffect(() => {
    getDetails();
  }, []);

  const getDetails = async () => {
    let response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      setDetails(response?.data?.data || {});
    }
  };

  const handlePreOrder = () => {
    dispatchOnboardingForms({ type: CHANGE_PREORDER_STATUS, payload: true });
    if (isMobile) {
      // toggleDrawer()
      navigate('/phone-onboarding');
    } else {
      navigate('/smart-field?type=pre-order');
    }
  };
  const divRef = useRef(null);
  const handleMouseLeave = (e) => {
    if (divRef.current) {
      divRef.current.style.setProperty('--background-nomouse', `#000 !important`);
      divRef.current.style.setProperty('--mouse-x', `100%`);
      divRef.current.style.setProperty('--mouse-y', `0%`);
      divRef.current.style.setProperty('--mouse-z', `100%`);
    }
  };

  const handleMouseMove = (e) => {
    lastKnownPosition = e.clientY;
    if (!ticking && !isMobile) {
      if (animationFrameId) {
        window.cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = window.requestAnimationFrame(function () {
        if (divRef.current) {
          const container = divRef.current.getBoundingClientRect();
          const mouseY = lastKnownPosition;
          const offsetY = mouseY - container.top;
          const y = (offsetY / container.height) * 100;
          if (y > 0 && y <= 100) {
            const mouseX = y - 10 < 0 ? 0 : y - 10;
            const mouseYVar = y < 0 ? 0 : y;
            const mouseZ = y + 10 > 100 ? 100 : y + 10;

            divRef.current.style.setProperty('--mouse-x', `${mouseX}%`);
            divRef.current.style.setProperty('--mouse-y', `${mouseYVar}%`);
            divRef.current.style.setProperty('--mouse-z', `${mouseZ}%`);
          }
          ticking = false;
        }
      });
      ticking = true;
    }
  };

  const handlePayment = async () => {
    if (props?.isMobile) {
      props?.goTo(7);
      props.setRegistered(false);
    } else if (type !== 'pre-order') {
      navigate('/at-your-convenience');
    } else {
      navigate('/at-your-convenience?type=pre-order');
    }
  };

  const goBack = () => {
    if (props?.isMobile) {
      props.goTo(4);
    } else {
      navigate(-1); // Navigate back one step in the history stack
    }
  };

  const initiationFees = () => {
    return (
      numberWithCommas(
        details?.discountInitiationFees
          ? Number(details?.discountInitiationFees) > 0
            ? Number(details?.discountInitiationFees)
            : ''
          : Number(details?.initiationFees),
      ) + ''
    );
  };

  if (!details || details?.status === 'inactive') {
    return null;
  }

  console.log(details, 'this_is_details');

  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      id='payment-card2'
      ref={divRef}
      className={`payment-card ${props?.paymentheight || ''}`}
    >
      {/* <div className="shiny"></div> */}
      <div className='payment-cardp !transition !duration-1000'>
        <div
          className='payment-header-wrapper'
          style={{
            height: details?.discountPrice ? '194px' : '114px',
          }}
        >
          {details?.discountPrice && (
            <div className='exclusive'>
              <p>
                {!details?.preOrder ? 'Secure your monthly price' : 'Exclusive pre-order price'}
              </p>
            </div>
          )}
          <div className='payment-header'>
            <div className='header-txt'>
              <div className='header-inner-section'>
                <div className='member-wrap'>
                  <div className='payment-list'>
                    <div className='redline-wrapper'>
                      {details?.discountPrice && (
                        <>
                          {/* */}
                          <h3 className='exclu-num'>
                            <TextStrick className='exclu-num'>
                              {numberWithCommas(details?.latestPrice)}
                            </TextStrick>
                          </h3>
                          {/* <img src='/images/img_vector20.svg' alt='vectorTwenty_One' /> */}
                        </>
                      )}
                      {!details?.discountPrice && (
                        <>
                          <h3 className='exclu-num latest-price-without-discount'>
                            {numberWithCommas(details?.latestPrice)}
                            <span className='month'> /mo</span>
                          </h3>
                        </>
                      )}
                    </div>
                  </div>
                  {details?.discountPrice && (
                    <div className='activate-txt 1'>
                      <span className='five-s discount-price'>
                        {numberWithCommas(details?.discountPrice)}
                      </span>
                      <span className='five-s'> </span>
                      <span className='month-discount'> /mo</span>
                    </div>
                  )}
                </div>
                {details?.discountPrice && (
                  <>
                    {details?.usedSeats < 10 && (
                      <p className='activate-txt 2'>
                        <span className='activate-txt'>Only </span>
                        <span className='five-text'>{details?.usedSeats}</span>
                        <span className='activate-txt'> memberships left at this price</span>
                      </p>
                    )}
                    {details?.usedSeats >= 10 && (
                      <p className='activate-txt 2'>
                        <span className='activate-txt'>Limited memberships left at this price</span>
                      </p>
                    )}
                  </>
                )}
              </div>
              <div className='unlimt-h'>
                <h1 className='unlimit-p'>{details?.name} Plan membership</h1>
              </div>
            </div>
          </div>
        </div>
        <div className='list-section '>
          <div className='list-inflex'>
            {details?.highlightsArray?.map((item, index) => {
              if (index === 5) {
                return (
                  <div className='list-img-txt' key={item?._id}>
                    <img src='/images/img_checkmark.svg' alt='checkmark_Five' />
                    <div className='five-wrap'>
                      <p className='waiv-d'>
                        {/* {console.log(Number(details?.discountInitiationFees), 'discountInitiationFees___')} */}
                        {details?.discountInitiationFees && (
                          <TextStrick>{numberWithCommas(details?.initiationFees)}</TextStrick>
                        )}{' '}
                        {initiationFees()} {item?.highlight || 'NA'}
                      </p>

                      <p className='waiv'>
                        <div className='five-txt'>
                          <div className='five-txt'>
                            {details?.discountInitiationFees && (
                              <TextStrick>{numberWithCommas(details?.initiationFees)}</TextStrick>
                            )}{' '}
                            {initiationFees()} {item?.highlight || 'NA'}
                          </div>
                        </div>
                      </p>
                    </div>
                  </div>
                );
              }
              return (
                <div className='list-img-txt' key={item?._id}>
                  <img src='/images/img_checkmark.svg' alt='checkmark_Five' />
                  <div className='five-wrap'>
                    <p className='flex gap-2 waiv-d'>{item?.highlight || 'NA'}</p>

                    <p className='waiv'>
                      <div className='five-txt'>
                        <p>
                          {/* {item?.strikeThroughHighlight} */}
                          {item?.highlight}
                        </p>
                        {/* {item?.strikeThroughHighlight && (
                          <img src='/images/img_vector20.svg' alt='vector7329' />
                        )} */}
                      </div>
                      <p className='fee-waiv'></p>
                    </p>
                  </div>
                </div>
              );
            })}
          </div>
          <Text className=' activate-txt' size='txtHauoraLight16'>
            <span
              className=' activate-txt '
              dangerouslySetInnerHTML={{ __html: details?.text }}
            ></span>
          </Text>
          {/* {details?.preOrder && (
            <Text className=' activate-txt' size='txtHauoraLight16'>
              <span className=' activate-txt '>
                *Activate your membership at your leisure when you’re ready to start flying after
                we’ve launched. Memberships activated will auto-renew monthly. You may cancel your
                membership anytime.{' '}
              </span>
              <span className=' activate-txt'>
                Cancellations apply at the end of the monthly billing cycle.
              </span>
            </Text>
          )}
          {!details?.preOrder && (
            <Text className=' activate-txt' size='txtHauoraLight16'>
              <span className=' activate-txt '>
                *Activate your membership at your leisure when you’re ready to start flying after
                we’ve launched. Memberships activated will auto-renew monthly. You may cancel your
                membership anytime.
              </span>
              <span className=' activate-txt'>
                Cancellations apply at the end of the monthly billing cycle.
              </span>
            </Text>
          )} */}
          {props.button && (
            <CommonButton
              onClick={handlePreOrder}
              className='preorder-btn pre-order-simmer-effect'
              text={<div> {!details.preOrder ? 'Become a member' : 'Pre-order now'} </div>}
            />
          )}
          {props?.onboarding && (
            <div className='form-buttons row gap-[48px] lg:md:gap-[10px]'>
              <button
                id='RectButtons'
                type='button'
                onClick={goBack}
                className='back-btn border-solid border-white flex flex-row justify-center p-4 w-16 h-12 cursor-pointer items-center border rounded'
              >
                <img
                  src='https://file.rendit.io/n/ln9TIOoBGF72MNAYYUyv.svg'
                  alt='ArrowLeft'
                  id='ArrowLeft'
                  className='w-4'
                />
              </button>
              {/* <button
                onClick={handlePayment}
                type="submit"
                id="RectButtons1"
                className="select-btn"
              >
                Select
              </button> */}
              <CommonButton
                text={
                  <div>
                    {details?.preOrder
                      ? 'Continue to payment'
                      : props?.isMobile
                      ? 'Purchase'
                      : 'Select'}
                  </div>
                }
                className={'select-btn pre-order-simmer-effect'}
                onClick={handlePayment}
              />
            </div>
          )}
        </div>
        {props.payment && (
          <div className='payment-section'>
            <div className='one-time-wrap'>
              <h1 className='one-time'>One-time Initiation & Verification fee</h1>
              <div className='waived-txt'>
                {details?.discountInitiationFees && (
                  <>
                    <div className='waived-txt-strike'>
                      <TextStrick>{numberWithCommas(details.initiationFees)}</TextStrick>
                    </div>
                    <p>
                      {`${
                        details?.discountInitiationFees > 0
                          ? numberWithCommas(details?.discountInitiationFees)
                          : 'Waived'
                      }`}
                    </p>
                  </>
                )}
                {!details?.discountInitiationFees && (
                  <>
                    <div className='waived-txt-strike'>
                      {numberWithCommas(details.initiationFees)}
                    </div>
                  </>
                )}
              </div>
            </div>

            <div className='foot-txt'>
              <div
                className='foot-inner
               
                '
              >
                <h1 className='total-txt'>Total </h1>
                <h1 className='gst-txt'>GST included</h1>
              </div>
              <h1 className='total-txt'>{getTotalPrice(details)}</h1>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

CardSection.defaultProps = {
  p3499: '3,499',
  mocountertext: (
    <Text
      className='md:text-2xl sm:text-[22px] text-[26px] text-center text-white-A700 w-auto'
      size='txtHauoraSemiBold26'
    >
      <span className='font-medium text-white-A700 sm:text-[18px] font-hauora'>2,499 </span>
      <span className='text-base font-light text-white-A700 sm:text-[12px] font-hauora'>/mo</span>
    </Text>
  ),
  preOrder: 'Pre-order now',
  flyfromprivatetext: '',
  descriptiontext2: (
    <>
      Enjoy a selection of gourmet snacks and freshly brewed coffee in our exclusive lounge before
      departure. <br />
      All our flights operate from private terminals, ensuring a serene ambiance. Relish our curated
      range of healthy treats and beverages as you prepare for your journey.
    </>
  ),
  whatyouflyintext: '',
  descriptiontext3:
    'Elevate your flying experience in our luxurious 8-seat cabin, a masterful creation brought to life by the design maestros at BMW Designworks',
  descriptiontext4:
    'Thanks to the fuel-efficient design of the turbine-driven propeller, flying on our aircraft consumes less fuel than if you were to drive the route, reducing your carbon footprint',
  descriptiontext5:
    'While the flight duration is on average a mere 20 minutes more than a commercial flight, our private terminals ensure you save a total of 130 minutes on each round trip',
  provensafetyrecordtext: 'Proven safety record',
  descriptiontext6:
    'With safe operation of over seven million flight hours and 1,700 aircrafts, the aircraft touts a safety record on par with twin-engine jets',
  descriptiontext7:
    'Powered  by a jet engine, the turbine-driven propeller combines jet reliability with propeller fuel efficiency ',
  descriptiontext8:
    'Even though our aircraft is designed for a single pilot, our policy is to operate with two for all flights, ensuring an apex of safety and redundancy when you fly',
  tourtheplane: 'Tour the plane',
  twentyOne: '2024',
  flyingprivateisOne: 'now a wise financial decision',
  p3499one: '3,499',
  mocounterOne: (
    <div className='activate-txt 1'>
      <span className='five-s'>2,499</span>
      <span className='five-s'> </span>
      <span className='five-s'>/mo</span>
    </div>
  ),
  only5membershipthree: (
    <p className='activate-txt 2'>
      <span className='activate-txt'>Only </span>
      <span className='five-s'>5</span>
      <span className='activate-txt'> memberships left at this price</span>
    </p>
  ),
  unlimitedplanmeOne: 'Unlimited Plan membership',
  exclusivepreordOne: 'Exclusive pre-order price',
  unlimitedallyouOne: 'Unlimited all-you-can-fly',
  durationOne: 'Guest Pass awarded every 3 months',
  bookchangeflighOne: 'Book/change flights in seconds',
  changecancelfliOne: 'Change/cancel flights freely 24h before departure',
  nohiddenfees: 'No hidden fees, predicable travel expenses',
  p3499two: '1,500',
  onetimeinitiatiOne: 'One time Initiation & Verification Fee waived',
  prepaythefirst: 'Prepay the first month to guarantee your monthly membership price',
  fullyrefundableOne: 'Fully refundable until you activate your membership*',
  descriptionFour: (
    <Text className=' activate-txt' size='txtHauoraLight16'>
      <span className=' activate-txt '>
        *Activate your membership at your leisure when you’re ready to start flying after we’ve
        launched. Memberships activated will auto-renew monthly. You may cancel your membership
        anytime.{' '}
      </span>
      <span className=' activate-txt'>
        Cancellations apply at the end of the monthly billing cycle.
      </span>
    </Text>
  ),
  // regFinderXBDC
  preOrderNowOne: 'Pre-order now',
  stillunsuretext: 'Still unsure?   Register for free and explore the app',
  createfreeaccountbutton1: 'Create a free account',
};

export default CardSection;
