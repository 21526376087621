import React, { useEffect, useState } from 'react';
import { Tabs, TabsHeader, TabsBody, Tab, TabPanel } from '@material-tailwind/react';
import Membership from './Membership';
import { getadmin, getviewAllLegal, getviewLegal } from 'services/api';
import useQueryParams from 'Hook/useQueryParams';
import { handleLegalSlide, isLegalOpened } from 'helpers';
import { Entermobilegetapp } from 'components/Popup';
import { useNavigate, useLocation } from 'react-router-dom';
import { ROUTE_LIST } from 'Routes';
import { Link } from 'react-router-dom';
import { useMediaQuery } from '@mui/material';
import FramerMotion from 'components/animations/FramerMotion';
const Legal = () => {
  const queryParams = useQueryParams();
  const type = queryParams.type || '';
  const [legalList, setLegalList] = useState(null);
  const [selectedId, setSelectedId] = useState('');
  const [selectedLegal, setSelectedLegal] = useState([]);
  const navigate = useNavigate();
  const location = useLocation();
  const isMobile = useMediaQuery('(max-width : 699px)');

  useEffect(() => {
    if (location.pathname === ROUTE_LIST.LEGAL) {
      if (!isLegalOpened()) {
        handleLegalSlide(true);
      }
    }

    (async () => {
      try {
        const res = await getviewAllLegal(10);
        console.log(res.data);
        setLegalList(res?.data?.data);
        if (res?.data?.data && res?.data?.data[0]) {
          const findType = res?.data?.data.find(
            (ele) => ele?.legalTitle.toLowerCase() === type.toLowerCase(),
          );
          setSelectedId(findType?._id || res?.data?.data[0]._id || '');
        }
      } catch (error) {
        console.log(error);
      }
    })();
  }, [type]);

  // useEffect(() => {
  //   if (type !== '') {
  //     let selectedMenu = legalList?.find((data) => data?.legalTitle === type);
  //     setSelectedId(selectedMenu?._id || '');
  //   }
  // }, [type, legalList]);

  const handleCloseLegal = () => {
    // setTimeout(() => {
      if (window.history.length > 2) {
        // Check for at least two entries (SPA + initial visit)
        navigate(-1);
      } else {
        navigate('/'); // Redirect to landing page if no previous history
      }
    // }, 250);
    // handleLegalSlide();
  };

  const legalJsx = (
    <div className='common-scroll-mob sticky-tab'>
      <img
        className='close-btn'
        src='images/close-icon-white.svg'
        alt=''
        onClick={handleCloseLegal}
      />
      <h1 className='legal-heading fixed-header-pages'>Legal</h1>
      <Tabs value='html' className='main-tabs' orientation='vertical'>
        <TabsHeader className='legal-tab-header '>
          {legalList?.map((legal) => (
            <Tab
              onClick={() => {
                setSelectedId(legal?._id);
              }}
              className={` ${
                selectedId == legal._id ? ' !text-[#ffffff] tab-t ' : ' tab-t'
              } !bg-inherit mb-6`}
              // "!bg-inherit text-[#f8f6f6] mb-6"
              key={legal?._id}
              value={legal?.legalTitle}
            >
              {isMobile ? (
                <div className='break-words'> {legal?.legalTitle}</div>
              ) : (
                <Link to={`/legal?type=${legal?.legalTitle}`} className='break-words'>
                  {legal?.legalTitle}
                </Link>
              )}
            </Tab>
          ))}
        </TabsHeader>

        <TabsBody className='faq-wrap-body legal-content-wrap'>
          <Membership id={selectedId} />
        </TabsBody>
        <span className='bottom-trademark'>©2023 Black Jet Mobility Pty Ltd</span>
      </Tabs>
    </div>
  );
  return (
    <FramerMotion>
      <div id='legal-mobile' aria-label='legal-mobile-page' className='legal min-h-screen'>
        <Entermobilegetapp />
        <div className='flex justify-between'></div>
        {!legalList && selectedId === '' ? <div>Loading...</div> : legalJsx}
      </div>
    </FramerMotion>
  );
};

export default Legal;
