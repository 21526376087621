import React, { useEffect, useReducer, useState } from 'react';
import Routes from './Routes';
import { Toaster } from 'react-hot-toast';
import { ParallaxProvider } from 'react-scroll-parallax';
import OnboardingContext, {
  OnboardingReducer,
  initialOnboardingState,
} from 'context/OnboardingContext';
import { getMembershipDetails } from 'api/onboarding';
import { MEMBERSHIP_DATA } from 'constants/actions';
import { BrowserRouter } from 'react-router-dom';

function App() {
  const [onboardingForms, dispatchOnboardingForms] = useReducer(
    OnboardingReducer,
    initialOnboardingState,
  );

  const getDetails = async () => {
    let response = await getMembershipDetails({ type: 'Unlimited' });
    if (response?.data?.status_code === 200) {
      dispatchOnboardingForms({ type: MEMBERSHIP_DATA, payload: response?.data?.data || {} });
    }
  };
  useEffect(() => {
    getDetails();
  }, []);

  useEffect(() => {
    document.addEventListener('wheel', function (event) {
      if (document.activeElement.type === 'number') {
        document.activeElement.blur();
      }
    });
    return () => {
      document.removeEventListener('wheel', null);
    };
  }, []);

  if (process.env.NODE_ENV !== 'development') {
    document.addEventListener('contextmenu', (e) => e.preventDefault());

    function ctrlShiftKey(e, keyCode) {
      return e.ctrlKey && e.shiftKey && e.keyCode === keyCode.charCodeAt(0);
    }

    document.onkeydown = (e) => {
      // Disable F12, Ctrl + Shift + I, Ctrl + Shift + J, Ctrl + U
      if (
        e.keyCode === 123 ||
        ctrlShiftKey(e, 'I') ||
        ctrlShiftKey(e, 'J') ||
        ctrlShiftKey(e, 'C') ||
        (e.ctrlKey && e.keyCode === 'U'.charCodeAt(0))
      )
        return false;
    };
  }

  return (
    <OnboardingContext.Provider value={{ onboardingForms, dispatchOnboardingForms }}>
      <React.Suspense fallback={<>Loading...</>}>
        <ParallaxProvider>
          <BrowserRouter>
            <Routes />
          </BrowserRouter>
        </ParallaxProvider>
      </React.Suspense>
      <Toaster
        position='bottom-center'
        reverseOrder={false}
        toastOptions={{
          style: {
            borderRadius: '0.75rem',
            background: 'rgba(255, 255, 255, 0.25)',
            backdropFilter: 'blur(7.5px)',
            color: '#FFF',
            width: '100%',
          },
        }}
      />
    </OnboardingContext.Provider>
  );
}

export default App;
