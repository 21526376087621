import { useMediaQuery } from "@mui/material";
import { Text } from "components";
import CommonButton from "components/formcomponents/CommonButton";
import { CHANGE_PREORDER_STATUS } from "constants/actions";
import OnboardingContext from "context/OnboardingContext";
import { toggleDrawer } from "helpers";
import useQueryParams from "Hook/useQueryParams";
import { useContext, useRef } from "react";
import { useNavigate } from "react-router";

let lastKnownPosition = 0;
let ticking = false;
let animationFrameId = null;

const MemberCard = (props) => {
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const type = queryParams.type || "";
  const isMobile = useMediaQuery("(max-width : 699px)");
  const {dispatchOnboardingForms } = useContext(OnboardingContext);

  const handlePreOrder = () =>{
    dispatchOnboardingForms({type: CHANGE_PREORDER_STATUS, payload: true})
    if(isMobile){
      // toggleDrawer()
      navigate("/phone-onboarding")
    }else{
      navigate("/smart-field?type=pre-order")
    }
  }
  const divRef = useRef(null);
  const handleMouseLeave = (e) => {
    if (divRef.current) {
      divRef.current.style.setProperty("--background-nomouse", `#000 !important`);
      divRef.current.style.setProperty("--mouse-x", `100%`);
      divRef.current.style.setProperty("--mouse-y", `0%`);
      divRef.current.style.setProperty("--mouse-z", `100%`);
    }
  };

  const handleMouseMove = (e) => {
    lastKnownPosition = e.clientY;
    if (!ticking && !isMobile) {
      if(animationFrameId){
        window.cancelAnimationFrame(animationFrameId);
      }
      animationFrameId = window.requestAnimationFrame(function () {
        if (divRef.current) {
          const container = divRef.current.getBoundingClientRect();
          const mouseY = lastKnownPosition;
          const offsetY = mouseY - container.top;
          const y = (offsetY / container.height) * 100;
          console.log("y",y)
          if (y > 0 && y <= 100) {
            const mouseX = y - 10 < 0 ? 0 : y - 10;
            const mouseYVar = y < 0 ? 0 : y;
            const mouseZ = y + 10 > 100 ? 100 : y + 10;

            divRef.current.style.setProperty('--mouse-x', `${mouseX}%`);
            divRef.current.style.setProperty('--mouse-y', `${mouseYVar}%`);
            divRef.current.style.setProperty('--mouse-z', `${mouseZ}%`);
          }
          ticking = false;
        }
      });
      ticking = true;
    }
  };

  const handlePayment = async () => {
    if (props?.isMobile) {
      props?.goTo(7)
      props.setRegistered(false)
    }
    else if (type !== "pre-order") {
      navigate("/at-your-convenience")
    } else {
      navigate("/at-your-convenience?type=pre-order")
    }
  }

  const goBack = () => {
    if (props?.isMobile) {
      props.goTo(4)
    } else {
      navigate(-1); // Navigate back one step in the history stack
    }
  };
  return (
    <div
      onMouseMove={handleMouseMove}
      onMouseLeave={handleMouseLeave}
      id="payment-card2"
      ref={divRef}
      className={`payment-card ${props?.paymentheight || ""}`}
    >
      {/* <div className="shiny"></div> */}
      <div className="payment-cardp !transition !duration-1000">
        <div className="payment-header-wrapper">
          <div className="exclusive">
            <p>{props?.exclusivepreordOne}</p>
          </div>
          <div className="payment-header">
            <div className="header-txt">
              <div className="header-inner-section">
                <div className="member-wrap">
                  <div className="payment-list">
                    <div className="redline-wrapper">
                      <h3 className="exclu-num">{props?.p3499one}</h3>
                      <img
                        src="/images/img_vector20.svg"
                        alt="vectorTwenty_One"
                      />
                    </div>
                  </div>
                  {props?.mocounterOne}
                </div>

                {props?.only5membershipthree}
              </div>
              <div className="unlimt-h">
                <h1 className="unlimit-p">{props?.unlimitedplanmeOne}</h1>
                {/* <Line className="w-full line h-px bg-white-A700" /> */}
              </div>
            </div>
          </div>
        </div>
        <div className="list-section ">
          <div className="list-inflex">
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark" />
              <p>{props?.unlimitedallyouOne}</p>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_One" />
              <p>{props?.durationOne}</p>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_Two" />
              <p>{props?.bookchangeflighOne}</p>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_Three" />
              <p>{props?.changecancelfliOne}</p>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_Four" />
              <p>{props?.nohiddenfees}</p>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_Five" />
              <div className="five-wrap">

                <p className="flex gap-2 waiv-d"> <div className="five-txt">
                  <p>{props?.p3499two}</p>
                  <img src="/images/img_vector20.svg" alt="vector7329" />
                </div> One time Initiation & Verification Fee waived </p>
                <p className="waiv"> <div className="five-txt">
                  <p>{props?.p3499two}   One time Initiation & Verification Fee waived</p>
                  <img src="/images/img_vector20.svg" alt="vector7329" />
                </div> <p className="fee-waiv"> </p></p>


              </div>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_Six" />
              <p>{props?.prepaythefirst}</p>
            </div>
            <div className="list-img-txt">
              <img src="/images/img_checkmark.svg" alt="checkmark_Seven" />
              <p>{props?.fullyrefundableOne}</p>
            </div>
          </div>
          {props?.descriptionFour}
          {props.button && (
            <button
              className="preorder-btn"
              shape="round"
              color="white_A700"
              size="md"
              variant="fill"
              onClick={handlePreOrder}
            >
              {props?.preOrderNowOne}
            </button>
          )}
          {props?.onboarding && (
            <div className="form-buttons row gap-[48px] lg:md:gap-[10px]">
              <button
                id="RectButtons"
                type="button"
                onClick={goBack}
                className="back-btn border-solid border-white flex flex-row justify-center p-4 w-16 h-12 cursor-pointer items-center border rounded"
              >
                <img
                  src="https://file.rendit.io/n/ln9TIOoBGF72MNAYYUyv.svg"
                  alt="ArrowLeft"
                  id="ArrowLeft"
                  className="w-4"
                />
              </button>
              {/* <button
                onClick={handlePayment}
                type="submit"
                id="RectButtons1"
                className="select-btn"
              >
                Select
              </button> */}
              <CommonButton text={props?.isPreOrder ? "Continue to payment" :props?.isMobile ? "Purchase" : "Select"} className={"select-btn"} onClick={handlePayment} />
            </div>
          )}


        </div>
        {props.payment && (
          <div className="payment-section">
            <div className="one-time-wrap">
              <h1 className="one-time">One-time Initiation & Verification fee</h1>
              <div className="waived-txt">
                <img
                  loading="lazy"
                  src="https://cdn.builder.io/api/v1/image/assets/TEMP/c8b1e311de76ec5d803ea59b753fb14f5cd918795e689f7c824aff0225fa3f5e?"
                  className="aspect-[3.75] object-contain object-center w-[45px] justify-center items-center overflow-hidden shrink-0 max-w-full my-auto"
                />
                <h1>waived</h1>
              </div>
            </div>

            <div className="foot-txt">
              <div
                className="foot-inner
               
                "
              >
                <h1 className="total-txt">Total </h1>
                <h1 className="gst-txt">GST included</h1>
              </div>
              <h1 className="total-txt">2,499</h1>

            </div>
          </div>
        )}
      </div>
    </div>
  );
};

MemberCard.defaultProps = {
  p3499: "3,499",
  mocountertext: (
    <Text
      className="md:text-2xl sm:text-[22px] text-[26px] text-center text-white-A700 w-auto"
      size="txtHauoraSemiBold26"
    >
      <span className="font-medium text-white-A700 sm:text-[18px] font-hauora">
        2,499{" "}
      </span>
      <span className="text-base font-light text-white-A700 sm:text-[12px] font-hauora">
        /mo
      </span>
    </Text>
  ),
  preOrder: "Pre-order now",
  flyfromprivatetext: "Fly from Private Terminals",
  descriptiontext2: (
    <>
      Enjoy a selection of gourmet snacks and freshly brewed coffee in our
      exclusive lounge before departure. <br />
      All our flights operate from private terminals, ensuring a serene
      ambiance. Relish our curated range of healthy treats and beverages as you
      prepare for your journey.
    </>
  ),
  whatyouflyintext: "What you fly in",
  descriptiontext3:
    "Elevate your flying experience in our luxurious 8-seat cabin, a masterful creation brought to life by the design maestros at BMW Designworks",
  descriptiontext4:
    "Thanks to the fuel-efficient design of the turbine-driven propeller, flying on our aircraft consumes less fuel than if you were to drive the route, reducing your carbon footprint",
  descriptiontext5:
    "While the flight duration is on average a mere 20 minutes more than a commercial flight, our private terminals ensure you save a total of 130 minutes on each round trip",
  provensafetyrecordtext: "Proven safety record",
  descriptiontext6:
    "With safe operation of over seven million flight hours and 1,700 aircrafts, the aircraft touts a safety record on par with twin-engine jets",
  descriptiontext7:
    "Powered  by a jet engine, the turbine-driven propeller combines jet reliability with propeller fuel efficiency ",
  descriptiontext8:
    "Even though our aircraft is designed for a single pilot, our policy is to operate with two for all flights, ensuring an apex of safety and redundancy when you fly",
  tourtheplane: "Tour the plane",
  launchingfalltwentyOne: "Launching Fall 2024",
  flyingprivateisOne: "Flying private is now a wise financial decision",
  p3499one: "3,499",
  mocounterOne: (
    <div className="activate-txt 1">
      <span className="five-s">
        2,499
      </span>
      <span className="five-s"> </span>
      <span className="five-s">
        /mo
      </span>
    </div>
  ),
  only5membershipthree: (
    <p className="activate-txt 2">

      <span className="activate-txt">
        Only{" "}
      </span>
      <span className="five-s">
        5
      </span>
      <span className="activate-txt">
        {" "}
        memberships left at this price
      </span>
    </p>
  ),
  unlimitedplanmeOne: "Unlimited Plan membership",
  exclusivepreordOne: "Exclusive pre-order price",
  unlimitedallyouOne: "Unlimited all-you-can-fly",
  durationOne: "Guest Pass awarded every 3 months",
  bookchangeflighOne: "Book/change flights in seconds",
  changecancelfliOne: "Change/cancel flights freely 24h before departure",
  nohiddenfees: "No hidden fees, predicable travel expenses",
  p3499two: "1,500",
  onetimeinitiatiOne: "One time Initiation & Verification Fee waived",
  prepaythefirst:
    "Prepay the first month to guarantee your monthly membership price",
  fullyrefundableOne: "Fully refundable until you activate your membership*",
  descriptionFour: (
    <Text
      className=" activate-txt"
      size="txtHauoraLight16"
    >
      <span className=" activate-txt ">
        *Activate your membership at your leisure when you’re ready to start
        flying after we’ve launched. Memberships activated will auto-renew
        monthly. You may cancel your membership anytime.{" "}
      </span>
      <span className=" activate-txt">
        Cancellations apply at the end of the monthly billing cycle.
      </span>
    </Text>
  ),
  preOrderNowOne: "Pre-order now",
  stillunsuretext: "Still unsure?   Register for free and explore the app",
  createfreeaccountbutton1: "Create a free account",
};

export default MemberCard;
