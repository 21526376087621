import { useMediaQuery } from '@mui/material';
import { ROUTE_LIST } from 'Routes';
import { Img, Text } from 'components';
import { handleFaqSlide } from 'helpers';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';

const IconSection = () => {
  const navigate = useNavigate();
  const isMobile = useMediaQuery('(max-width:699px)');
  const handleFAQ = () => {
    navigate(ROUTE_LIST.FAQ);
    setTimeout(() => {
      handleFaqSlide();
    }, 1);
  };
  return (
    <div>
      <div className='social-media-wrap'>
        <Link to='/'>
          {' '}
          <img className='brand-logo' src='/images/img_television.svg' alt='' />
        </Link>
        <div className='social-wrap'>
          <ul>
            <li>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.facebook.com/joinBlackJet'
              >
                <img src='/images/img_socialiconfacebook.svg' alt='' />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.linkedin.com/company/joinblackjet'
              >
                <img src='/images/img_iconlinkedin.svg' alt='' />
              </a>
            </li>
            <li>
              <a
                target='_blank'
                rel='noopener noreferrer'
                href='https://www.instagram.com/joinblackjet'
              >
                <img src='/images/img_socialiconinstagram.svg' alt='' />
              </a>
            </li>
            <li>
              <a target='_blank' rel='noopener noreferrer' href='https://x.com/joinBlackJet'>
                <img src='/images/img_socialicontwitter.svg' alt='' />
              </a>
            </li>
          </ul>
        </div>
        <div onClick={handleFAQ} className='option-text'>
          <div className='faq-link-wrap'>
            <p>FAQs </p>
            <img src='/images/img_arrowright.svg' alt='' />
          </div>
        </div>
      </div>
    </div>
  );
};

export default IconSection;
