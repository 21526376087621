import { useMediaQuery } from '@mui/material';
import { ROUTE_LIST } from 'Routes';
import { Entermobilegetapp } from 'components/Popup';
import FramerMotion from 'components/animations/FramerMotion';
import EnquiryForm from 'components/enquiryform/EnquiryForm';
import { handleContactSlide, handleFaqSlide, isContactOpened } from 'helpers';
import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

const GetContact = () => {
  const isMobile = useMediaQuery('(max-width : 699px)');
  const navigate = useNavigate();
  const { pathname } = useLocation();
  useEffect(() => {
    if (isMobile && pathname === ROUTE_LIST.CONTACT_US) {
      if (!isContactOpened()) {
        handleContactSlide(true);
      }
    }
  }, [isMobile, pathname]);

  const handleCloseContactUs = () => {
    // setTimeout(() => {
      if (window.history.length > 2) {
        // Check for at least two entries (SPA + initial visit)
        navigate(-1);
      } else {
        navigate('/'); // Redirect to landing page if no previous history
      }
    // }, 250);
    // handleContactSlide();
  };

  return (
    <FramerMotion>
      <div id='contact-mobile'>
        <Entermobilegetapp />
        <img
          className='close-btn'
          src='images/close-icon-white.svg'
          alt=''
          onClick={handleCloseContactUs}
        />
        <EnquiryForm type={'contact_us'} enquirybg='contact-form'>
          <div className='contact-heading fixed-header-pages'>Get In Contact</div>
        </EnquiryForm>
        {isMobile && (
          <span className='bottom-trademark-contact'>©2023 Black Jet Mobility Pty Ltd</span>
        )}
      </div>
    </FramerMotion>
  );
};

export default GetContact;
