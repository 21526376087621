import CardSection from 'components/CardSection';
import React, { useContext, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import ScrollToTopOnMount from 'components/layout/ScrollToTopOnMount';
import FreePreviewCard from 'components/FreePreviexCard';
import useQueryParams from 'Hook/useQueryParams';
import CommonButton from 'components/formcomponents/CommonButton';
import { onboard } from 'api/onboarding';
import OnboardingContext from 'context/OnboardingContext';
import { CHANGE_PREORDER_STATUS } from 'constants/actions';
import MemberCard from 'components/CardSection/MemberCard';

const MembershipSelection = ({ goTo, isMobile, isPreOrder, setRegistered = () => {} }) => {
  const queryParams = useQueryParams();
  const type = queryParams.type || '';
  const navigate = useNavigate();
  const goBack = () => {
    navigate(-1); // Navigate back one step in the history stack
  };

  const location = useLocation();
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);
  useEffect(() => {
    if (location.pathname === '/refined-selection' && !onboardingForms?.sessionToken) {
      dispatchOnboardingForms({ type: CHANGE_PREORDER_STATUS, payload: false });
      navigate('/smart-field');
    }
  }, []);

  const handlePayment = async () => {
    if (type !== 'pre-order') {
      navigate('/at-your-convenience');
    } else {
      navigate('/at-your-convenience?type=pre-order');
    }
  };

  const handleFree = async () => {
    if (isMobile) {
      let response = await onboard();
      if (response?.data?.status_code === 200) {
        goTo(8);
        setRegistered(true);
      }
    } else if (type !== 'pre-order') {
      navigate('/gratias-tibi-ago');
    } else {
      navigate('/gratias-tibi-ago?type=pre-order');
    }
  };
  const heading = location?.state?.heading ? (
    <h2 className='heading-membership-selection'>{location?.state?.heading}</h2>
  ) : (
    <h1 className='choose-txt'>Choose a plan</h1>
  );
  const subHeading = location?.state?.subHeading && <h2 className='sub-heading-membership-selection'>{location.state.subHeading}</h2>;

  return (
    <div className='free-card membership-card-login'>
      <ScrollToTopOnMount />
      {!isMobile && (
        <div className='headings-membership-selection'>
          {heading}
          {subHeading}
        </div>
      )}
      <div className='free-card-main'>
        <div className='col-md-6 card-section-wrapper !justify-end'>
          <CardSection
            setRegistered={setRegistered}
            isPreOrder={isPreOrder}
            goTo={goTo}
            isMobile={isMobile}
            paymentheight='free-payment'
            payment={false}
            onboarding={true}
          />
        </div>
        {!isMobile && (
          <div className='col-md-6 card-section-wrapper !justify-start'>
            <FreePreviewCard
              setRegistered={setRegistered}
              payment={false}
              goTo={goTo}
              isMobile={isMobile}
              freePreview={true}
            />
          </div>
        )}

        {!isPreOrder && isMobile && (
          <div className='non-payment-container'>
            <CommonButton
              text={'Continue without purchasing'}
              className={'select-btn non-payment'}
              onClick={handleFree}
            />
          </div>
        )}

        {/* <div className="col-md-6">
                    <div className=" ">
                        <button
                            id="RectButtons"
                            type="button"
                            onClick={goBack}
                            className="border-solid border-white flex flex-row justify-center p-4 w-16 h-12 cursor-pointer items-center border rounded"
                        >
                            <img
                                src="https://file.rendit.io/n/ln9TIOoBGF72MNAYYUyv.svg"
                                alt="ArrowLeft"
                                id="ArrowLeft"
                                className="w-4"
                            />
                        </button>
                        <button
                            onClick={handlePayment}
                            type="submit"
                            id="RectButtons1"
                            className="select-btn"
                        >
                            Select
                        </button>
                    </div>
                </div>
                <div className="col-md-6">
                  
                        <button
                            onClick={handleFree}
                            type="submit"
                            id="RectButtons1"
                            className="select-btn"
                        >
                            Select
                        </button>
                   
                </div> */}
      </div>
    </div>
  );
};

export default MembershipSelection;
