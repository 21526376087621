import Errors from 'components/errors/Errors';
import CommonButton from 'components/formcomponents/CommonButton';
import ResendButton, { INITIAL_TIMING } from 'components/onboarding/components/ResendButton';
import { UPDATE_EMAIL, UPDATE_PHONE } from 'constants/actions';
import OnboardingContext from 'context/OnboardingContext';
import { openApp } from 'helpers';
import useQueryParams from 'Hook/useQueryParams';
import { MuiOtpInput } from 'mui-one-time-password-input';
import React, { useContext, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  apiSendCodeToEmail,
  apiSendOtpEmailRegister,
  loginResendotp,
  loginuserotp,
} from 'services/api';

const OtpScreen = ({
  onboarded,
  isMobile,
  goTo,
  setIsAlready,
  device,
  currentIndex,
  fromWhereData,
  setKey = () => {},
}) => {
  const [otp, setOtp] = useState('');
  const [errorOtp, setErrorOtp] = useState('');
  const [countdown, setCountdown] = useState(0);
  const [checkNumber, setCheckNumber] = useState(true);
  const { onboardingForms, dispatchOnboardingForms } = useContext(OnboardingContext);
  const navigate = useNavigate();
  const queryParams = useQueryParams();
  const type = queryParams.type || '';
  const checkRegType = onboardingForms?.loginData?.newUser ? 'registered' : 'login';
  // useEffect(() => {
  //   resendOtp();
  // }, [currentIndex]);

  useEffect(() => {
    if (countdown === 10) {
      resendTimer();
    }
  }, [countdown]);

  useEffect(() => {
    if (otp.length === 6) {
      verifyOtp();
    }
  }, [otp]);

  const handleChange = (newValue) => {
    setOtp(newValue);
  };
  const goBack = () => {
    setOtp('');
  };

  const otpSend = () => {
    verifyOtp();
  };

  const resendTimer = () => {
    setOtp('');
    const countdownInterval = setInterval(() => {
      setCountdown((prevCountdown) => {
        // Ensure the countdown doesn't go below 0
        return prevCountdown > 0 ? prevCountdown - 1 : 0;
      });
    }, 1000);

    return () => {
      clearInterval(countdownInterval);
    };
  };

  const isSMS = device === 'phone' ? 'SMS' : 'email';

  const verifyOtp = async () => {
    if (!otp) {
      return setErrorOtp(`Please enter ${isSMS} code`);
    }
    if (otp.length < 6) {
      return setErrorOtp(`The ${isSMS} code you’ve entered is incorrect`);
    }
    const verify_from = checkNumber ? 0 : 1;
    let payload = {
      otp: otp,
      // firebase_device_token: 'abc',
      checkRegType,
      verify_from,
      randomString: onboardingForms?.loginData?.randomString,
    };

    //verify otp
    try {
      const res = await loginuserotp(payload);
      if (res?.data?.status_code === 200) {
        localStorage.setItem('blackjet-website', res?.data?.data?.token);
        if (onboarded) {
          // if onboarded
          if (isMobile) {
            // goTo(8)
            // setIsAlready(true)
            openApp();
            navigate('/');
            setKey((key) => key + 1);
          }
        } else {
          // if mobile goto email checking screen
          if (isMobile) {
            if (fromWhereData?.from === 'sendOtpEmailRegister') {
              goTo(5);
              if (isMobile) {
                // mobile -> goto basic info screen
                localStorage.setItem('last', 5);
                setIsAlready(false);
              } else if (type !== 'pre-order') {
                navigate('/compendium');
              } else {
                navigate('/compendium?type=pre-order');
              }
              return;
            }

            goTo(currentIndex === 3 ? 5 : 4);
          }
        }
      } else {
        setErrorOtp(res?.data?.message);
      }
      // }
    } catch (error) {
      console.log(error);
    }
  };
  // const checkRegType = onboardingForms?.loginData?.newUser ? 'registered' : 'login';
  console.log(fromWhereData, 'fromWhereData__');
  const resendOtp = async (setCount) => {
    if (setCount) {
      setCount(INITIAL_TIMING);
    }

    if (fromWhereData?.from === 'sendOtpEmailRegister') {
      const response = await apiSendOtpEmailRegister(fromWhereData.payload);
      return;
    }

    if (fromWhereData?.from === 'emailLogin') {
      const response = await apiSendCodeToEmail({
        ...fromWhereData.payload,
        randomString: onboardingForms?.loginData?.randomString,
      });
      return;
    }

    let payload = {
      phone_code: onboardingForms?.loginData?.phone_code || onboardingForms?.phone?.countryCode,
      phone: onboardingForms?.loginData?.phone || onboardingForms?.phone?.mobile,
      randomString: onboardingForms?.loginData?.randomString,
      checkRegType,
    };
    // let payload = { otp: otp, firebase_device_token: "abc" };
    try {
      const res = await loginResendotp(payload);
      console.log(res.data);
      // showMessage(res?.data?.message);
      setCountdown(INITIAL_TIMING);
    } catch (error) {
      console.log(error);
      // showError(error?.response?.data?.message)
    }
  };
  return (
    <div className='otp-screen'>
      <div className={`otp mt-[24px] ${errorOtp ? 'error-indicator' : ''}`}>
        <div className='otp-instructions text-left text-[16px] mb-1 sm:text-lg sm:mb-4  text-white'>
          {device.isFullTxt ? device.text : `Enter the 6 digit code sent to your ${device}`}
        </div>
        <MuiOtpInput
          length={6}
          // TextFieldsProps={{ placeholder: "-" }}
          TextFieldsProps={{
            type: 'number',
            inputProps: { inputMode: 'numeric', pattern: '[0-9]*' },
          }}
          value={otp}
          inoutbg='#333333'
          onChange={handleChange}
          display='flex'
          alignItems='center'
          justifyContent='center'
        />
        <Errors error={errorOtp} message={errorOtp} />
      </div>
      <div className='resend-btn otp-3 flex flex-row justify-start mt-3 mb-[48px]  h-10 items-start '>
        <ResendButton resendOtp={resendOtp} />
        {/* <button
          onClick={() => resendOtp()}
          id='ResendCode'
          disabled={countdown != 0 ? true : false}
          className="resend-click text-center !bg-[#333333] disabled:cursor-not-allowed disabled:text-gray-600 text-sm font-['Hauora']  p-2 rounded-[39px] text-[#f2f2f2]"
        >
          Resend code 23123123 {countdown == 0 ? '' : `(${countdown})`}
        </button> */}
      </div>
      {/* <div className='text-white text-left text-xs w-full mt-[5px]'>
        {errorOtp && (
          <div className='flex gap-[2px] items-center'>
            {' '}
            <svg
              className='mr-1'
              width='12'
              height='12'
              viewBox='0 0 12 12'
              fill='none'
              xmlns='http://www.w3.org/2000/svg'
            >
              <g clip-path='url(#clip0_681_2376)'>
                <path
                  d='M5.63096 6.81741C5.66271 6.99235 5.81582 7.12501 5.99991 7.12501C6.20702 7.12501 6.37491 6.95711 6.37491 6.75001V3.37339L6.36887 3.30598C6.33712 3.13104 6.18401 2.99839 5.99991 2.99839C5.79281 2.99839 5.62491 3.16628 5.62491 3.37339V6.75001L5.63096 6.81741ZM5.40088 8.43751C5.40088 8.74817 5.65272 9.00001 5.96338 9.00001C6.27404 9.00001 6.52588 8.74817 6.52588 8.43751C6.52588 8.12685 6.27404 7.87501 5.96338 7.87501C5.65272 7.87501 5.40088 8.12685 5.40088 8.43751ZM0 6C0 9.31371 2.68629 12 6 12C9.31371 12 12 9.31371 12 6C12 2.68629 9.31371 0 6 0C2.68629 0 0 2.68629 0 6ZM11.25 6C11.25 8.8995 8.8995 11.25 6 11.25C3.1005 11.25 0.75 8.8995 0.75 6C0.75 3.1005 3.1005 0.75 6 0.75C8.8995 0.75 11.25 3.1005 11.25 6Z'
                  fill='#FF0000'
                />
              </g>
              <defs>
                <clipPath id='clip0_681_2376'>
                  <rect width='12' height='12' fill='white' />
                </clipPath>
              </defs>
            </svg>
            <p>{errorOtp}</p>
          </div>
        )}
      </div> */}

      <div className="form-buttons flex flex-row gap-[48px] w-full font-['Hauora'] items-start">
        <CommonButton
          error={errorOtp || otp?.length < 6}
          text={'Continue'}
          onClick={otpSend}
          className=''
        />
      </div>
    </div>
  );
};

export default OtpScreen;
