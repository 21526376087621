import { useNavigate } from "react-router-dom";

const DesktopOnlyPage = ({heading="Investors"}) => {
    const navigate = useNavigate();
    const handleClose = () => {
        navigate("/")
    }
    return <div className="screen-not-available">
        <div className="top-section">
            <img onClick={handleClose} className="close-btn" src="images/close-icon-white.svg" alt="" />
            <h1>{heading}</h1>
            <div></div>
        </div>
        <div className="mid-text">
            <p className="mid-top-text">We're sorry, this page is not supported on mobile devices</p>
            <p className="mid-bottom-text">Please visit this page on a desktop browser. We apologize for any inconvenience.</p>
        </div>
        <span className="bottom-trademark">©2023 Black Jet Mobility Pty Ltd</span>
    </div>
}

export default DesktopOnlyPage;